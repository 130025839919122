import { h } from "preact";
import MailIcon from "../icons/mail";
import VkIcon from "../icons/vk";
import TgIcon from "../icons/tg";

const NETWORKS = [{
  id: 'VK',
  name: 'ВКонтакте',
  icon: () => <VkIcon />
}, {
  id: 'TG',
  name: 'Telegram',
  icon: () => <TgIcon />
}, {
  id: 'EM',
  name: 'Email',
  icon: () => <MailIcon />
}]

export default ({ networks, title, value, onChange }: { networks: string[], title: string, value: string, onChange: (value: string) => any }) => {
  return (
    <div class="ss-network-select">
      <div class="ss-network-select-title">{title || ''}</div>
      <div class="ss-network-select-networks">
        {NETWORKS.filter(({ id }) => networks.includes(id)).map(({ id, name, icon }) => (
          <div class={`ss-network-select-item${value === id ? ' selected' : ''}`} onClick={() => onChange(id)}>
            {icon()}<span>{name}</span>
          </div>
        ))}
      </div>
    </div>
  )
}