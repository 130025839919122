import { h } from "preact";

const OptInIcon = () => (
    <svg width="54" height="51" viewBox="0 0 54 51" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.73828 50.659H47.1676H50.5371C52.3973 50.659 53.9062 49.1458 53.9062 47.2812V21.9443L26.9531 38.3529L6.73828 50.659Z" fill="#53C4F7" />
        <path d="M53.2895 20.6534L43.985 13.0086L36.9288 7.21107L29.0552 0.742087C27.8188 -0.25096 26.0904 -0.248147 24.8347 0.755329L0.616875 20.6534C0.251953 20.9533 0.0335156 21.3919 0.00585938 21.8613H53.9009C53.8733 21.3918 53.655 20.9532 53.2895 20.6534Z" fill="#375BC1" />
        <path d="M26.9529 38.3692L0 21.961V47.276C0 49.1407 1.89703 50.654 3.75738 50.654H6.82828L26.9529 38.3692Z" fill="#13AEF5" />
        <path d="M53.9005 21.8613H0.00550792C0.00398448 21.8875 0.00222659 21.9136 0.0011719 21.94C0.000937527 21.947 0 21.954 0 21.961L26.9529 38.3692L53.906 21.961C53.906 21.9275 53.9025 21.8945 53.9005 21.8613Z" fill="#477CE3" />
        <path d="M22.5957 14.505L17.832 19.2818L26.9523 28.4269L49.5491 5.76898L44.7852 0.992065L26.952 18.8734L22.5957 14.505Z" fill="#E3E3E3" />
    </svg>
)

export default OptInIcon