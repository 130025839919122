import { h } from "preact";
import { Form } from "../types/Form";
import CheckboxGroup from "./ui/CheckboxGroup";
import RadioGroup from "./ui/RadioGroup";

const Field = ({
  field: { type, name, label, rows, placeholder, radioOptions, labels },
  value,
  onChange,
  invalid,
}: {
  field: Form.Field;
  value: any;
  onChange: (value: any) => any;
  invalid: boolean;
}) => {
  let input;
  switch (type) {
    case "textarea":
      input = (
        <div class="ss-input-wrap">
          <textarea
            class="ss-input"
            name={name}
            id={name}
            value={value}
            placeholder={placeholder}
            rows={rows || 5}
            onInput={({ target }) =>
              onChange((target as HTMLInputElement)!.value)
            }
          />
        </div>
      );
      break;
    case "radio":
      input = (
        <RadioGroup
          name={name}
          value={value}
          options={labels.map(({ value }) => value)}
          onChange={onChange}
        />
      );
      break;
    case "checkbox":
      input = (
        <CheckboxGroup
          name={name}
          value={value}
          options={labels.map(({ value }) => value)}
          onChange={onChange}
        />
      );
      break;
    default:
      input = (
        <div class="ss-input-wrap">
          <input
            type="text"
            name={name}
            id={name}
            value={value}
            placeholder={placeholder}
            onInput={({ target }) =>
              onChange((target as HTMLInputElement)!.value)
            }
            class="ss-input"
          />
        </div>
      );
      break;
  }
  return (
    <div class={`${invalid ? "ss-invalid" : ""}`}>
      <label for={name} class="ss-label">
        {label}
      </label>
      {input}
    </div>
  );
};

export default Field;
