import { Base64 } from "js-base64";
import { h, render } from "preact";
import Form from "./components/Form";
import SessionConfirmed from "./components/session/SessionConfirmed";
import SessionExpired from "./components/session/SessionExpired";
import useJsonp from "./hooks/jsonp";
import "./index.css";


const jsonp = useJsonp()

document.querySelectorAll('[data-ss-form]').forEach(async container => {
  const attr = container.getAttribute('data-ss-form')
  if (!container.innerHTML && attr) {
    let form
    try {
      form = JSON.parse(Base64.decode(attr))
    } catch (err) {
      form = await jsonp.getForm(attr)
    }
    const widget = h(Form, {
      form,
      params: {
        mode: container.getAttribute('data-mode') as 'web' | 'preview' | 'page' || 'web',
        hideBranding: false
      }
    })
    render(widget, container)
  }
})

document.querySelectorAll('[data-ss-session]').forEach(container => {
  if (container.hasAttribute('data-confirmed')) {
    render(h(SessionConfirmed, {
      redirect: container.getAttribute('data-redirect') || ''
    }), container)
  } else if (container.hasAttribute('data-expired')) {
    render(h(SessionExpired, {}), container)
  }
})