export const collectUtm = (): Record<string, string> => {
    const search = new URLSearchParams(location.search)
    return [
        'utm_source',
        'utm_medium',
        'utm_campaign',
        'utm_content',
        'utm_term'
    ]
        .map(key => [key, search.get(key)])
        .filter(([, value]) => !!value)
        .reduce((acc, [key, value]) => ({ ...acc, [key!]: value }), {})
}