import { h } from "preact";
import OptInIcon from "../icons/opt-in";
import Paragraph from "./Paragraph";

export default ({ text }: { text: string }) => (
  <div class="ss-opt-in">
    <OptInIcon />
    <div class="ss-opt-in-notice">
      <Paragraph text={text} />
    </div>
  </div>
)