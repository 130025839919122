import Cookies from "js-cookie"
import * as base32 from "hi-base32"

export const collectSubscriberFields = (formId: string): Record<string, any> => ({
    _source: 'site_form',
    _source_domain: location.host,
    _source_page: location.pathname,
    _source_form_id: formId,
})

export const insertImg = (url: string) => {
    const img = document.createElement('img');
    img.src = url;
    img.setAttribute('style', 'display: none');
    document.body.appendChild(img);
    img.onload = () => document.body.removeChild(img);
}

export const trackView = (subscriptionId = '', formId = '') => {
    const cookieId = `ss_track_view_${subscriptionId}_${formId}_${location.pathname.replace(/\/+/g, "")}`;
    if (Cookies.get(cookieId)) {
        return;
    }
    insertImg(
        `${((window as any).SocialSend?._config?.trackHost || 'https://track.ssnd.ru/')}${subscriptionId}/${base32.encode(JSON.stringify(collectSubscriberFields(formId)))}`
    );
    Cookies.set(cookieId, 'true', { expires: 60 * 30 });
}