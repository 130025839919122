import { h } from "preact";
import Paragraph from "./Paragraph";

const PolicyField = ({ text, url, value, onChange }: { text: string, url: string, value: boolean, onChange: (value: boolean) => any }) => (
    <div class={`ss-policy${value ? '' : ' ss-invalid'}`}>
        <label class="ss-checkbox">
            <input name="policy-check"
                type="checkbox"
                checked={value}
                onChange={({ target }) => onChange((target as HTMLInputElement).checked)} />
            <span>
                <Paragraph text={text} /> <a href={url} target="_blank">политики конфиденциальности</a>
            </span>
        </label>
    </div>
)

export default PolicyField