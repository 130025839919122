import { h } from "preact";

export default ({
  name,
  value,
  options,
  onChange,
}: {
  name: string;
  value: any;
  options: string[];
  onChange: (value: string[]) => any;
}) => {
  return (
    <div class="ss-checkbox-wrap">
      {options?.map((label) => (
        <label
          class={`ss-checkbox${
            value && value.indexOf(label) !== -1 ? " ss-checked" : ""
          }`}
        >
          <span class="ss-checkbox-ctrl">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon-tabler icon-tabler-check"
              width="16"
              height="16"
              viewBox="0 0 24 24"
              stroke-width="2.5"
              stroke="#ffffff"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M5 12l5 5l10 -10" />
            </svg>
          </span>
          <input
            name={name}
            type="checkbox"
            onChange={({ target }) => {
              const checkbox = target as HTMLInputElement;
              value = Array.isArray(value) ? value : [];
              if (checkbox.checked) {
                onChange([...value, checkbox.value]);
              } else {
                onChange(value.filter((v: any) => v !== checkbox.value));
              }
            }}
            value={label}
          />
          {label}
        </label>
      ))}
    </div>
  );
};
