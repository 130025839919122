import fetchJsonp from "fetch-jsonp"
import { FormDto } from "../types/Form"
import { Base64 } from "js-base64"

type Jsonp = {
    getForm: (id: string) => Promise<FormDto>
    createSession: (formId: string, label: string, fields: Record<string, string>) => Promise<{ id: string }>
    updateSession: (id: string, formId: string, fields: Record<string, any>) => Promise<{}>
}

const baseUrl = (window as any).SocialSend?._config?.host || 'https://ssndpage.com/'

const useJsonp = (): Jsonp => {
    return {

        getForm: (id: string) => fetchJsonp(`${baseUrl}form/${id}`)
            .then(resp => resp.json()),

        createSession: (formId: string, label: string, fields: Record<string, string>) =>
            fetchJsonp(`${baseUrl}session?formId=${formId}&label=${label}&fields=${encodeURIComponent(Base64.encode(JSON.stringify(fields)))}`)
                .then(resp => resp.json()),

        updateSession: (id: string, formId: string, fields: Record<string, any>) =>
            fetchJsonp(`${baseUrl}session/${id}?formId=${formId}&fields=${encodeURIComponent(Base64.encode(JSON.stringify(fields)))}`)
                .then(resp => resp.json())

    }
}

export default useJsonp