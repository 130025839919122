import { h } from "preact";
import MailIcon from "../icons/mail";
import TgMonoIcon from "../icons/tg-mono";
import VkIcon from "../icons/vk";
import VkMonoIcon from "../icons/vk-mono";

const SubmitButton = ({
  caption,
  network,
  url,
  onClick,
  disabled,
}: {
  caption: string;
  network: string;
  url?: string;
  onClick: () => any;
  disabled: boolean;
}) => {
  const content = [<span>{caption || ""}</span>];
  if (network === "EM") {
    content.push(<MailIcon />);
  }
  if (network === "VK") {
    content.push(<VkMonoIcon />);
  }
  if (network === "TG") {
    content.push(<TgMonoIcon />);
  }
  const className = `ss-submit${disabled ? " ss-disabled" : ""}`;
  return url ? (
    <a class={className} href={url} target="_blank" onClick={onClick}>
      {content}
    </a>
  ) : (
    <button
      class={className}
      onClick={() => !disabled && onClick()}
      disabled={disabled}
    >
      {content}
    </button>
  );
};

export default SubmitButton;
