import { isValid as isSimpleDateValid } from "./SimpleDate"

const patterns: Record<string, any> = {
    email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
    phone: /^[ +\d()-]{10,15}$/,
    cyrillic: /^[А-я]+(((-|[А-я])+[А-я]+)|)$/i,
    url: /(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?\S+([\-.][a-z0-9]+)*\.\S{2,5}(:[0-9]{1,5})?(\/.*)?/,
    word: /^[а-яА-ЯёЁa-zA-Z]+$/,
    date: /(^\d{1,2}\.\d{1,2}((\.\d{4})?)$)|(^\d{1,2}\s(?:января|февраля|марта|апреля|мая|июня|июля|августа|сентября|октября|ноября|декабря)(\s\d{4})?$)/i,
    num: /^(-?)\d+$/,
    floatNum: /^(-?)\d+(\.?)\d+$/,
    numWithSpaces: /^\d{1,3}(\s\d{3})*$$/i
};

const getPatternList = (name: string) => {
    const list = [patterns[name] || ''];
    if (name === 'num') {
        list.push(patterns['numWithSpaces']);
        list.push(patterns['floatNum']);
    }
    return list;
}

export const validateByPattern = (patternName: string, val: any) => {
    if (!patternName) {
        return !!(val + '').length;
    }
    if (patternName === 'date') {
        return isSimpleDateValid(val);
    }
    let valid = false;
    getPatternList(patternName).forEach((pattern) => {
        if (pattern && pattern.test((val + '') || '')) {
            valid = true;
        }
    });
    return valid;
}