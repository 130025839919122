import { h } from "preact"
import { useEffect } from "preact/hooks"

export default ({ redirect }: { redirect: string }) => {
    useEffect(() => {
        if (redirect) {
            // setTimeout(() => location.href = redirect, 3000)
        }
    }, [])
    return (
        <div class="ss-h-screen ss-flex ss-justify-center ss-items-center ss-text-center ss-text-gray-700">
            <div class="ss-text-xl ss-font-semibold">
                Спасибо за подписку!
                {redirect && (
                    <div class="ss-text-lg ss-font-semibold ss-mt-4">
                        Сейчас произойдет переход...
                        <div class="ss-text-base">
                            Если ничего не происходит, перейдите по <a class="ss-text-blue-500 ss-underline" href={redirect}>ссылке</a>.
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}