import { useMemo, useState } from "preact/hooks"
import { Form } from "../types/Form"
import { validateByPattern } from "../utils/patterns"

type Validate = {
    validate: (network: string, values: Record<string, any>, silent?: boolean) => boolean,
    reset: () => void,
    valid: boolean,
    invalidFieldNames: string[]
}

const useValidate = (fields: Form.Field[]): Validate => {

    const [invalidFieldNames, setInvalidFieldNames] = useState<string[]>([])
    const valid = useMemo(() => invalidFieldNames.length > 0, [invalidFieldNames])

    return {
        validate: (network: string, values: Record<string, any>, silent = false) => {
            const invalidNames: string[] = []
            fields.forEach(({ name, pattern, required }) => {
                const value = values[name]
                if (required && ((!value && value !== 0) || (Array.isArray(value) && !value.length))) {
                    invalidNames.push(name)
                }
                if (value && pattern && pattern !== 'false' && !validateByPattern(pattern, value)) {
                    invalidNames.push(name)
                }
            })
            if (network === 'EM' && !values['email']) {
                invalidNames.push('email')
            }
            if (!silent) {
                setInvalidFieldNames(invalidNames)
            }
            return invalidNames.length === 0
        },
        reset: () => setInvalidFieldNames([]),
        valid,
        invalidFieldNames,
    }
}

export default useValidate