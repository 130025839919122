import { useEffect, useState } from "preact/hooks";
import { Form } from "../types/Form";

type FormShow = {
  state: boolean;
  canHide: boolean;
  setState: (value: boolean) => void;
  show: () => void;
  hide: () => void;
};

const useFormShow = (
  formId: string,
  mode: "web" | "preview" | "page",
  formView: string,
  { showAgainFrom, scrollPercent, condition, delay }: Form.FormViewOptions
): FormShow => {
  const isPreview = mode === "preview";

  const [state, setState] = useState(formView === "form" || isPreview);

  const showAtStorageKey = `ss-form-${formId}-show-at`;

  const show = () => {
    setState(true);
    if (!isPreview && formView === "popup") {
      try {
        localStorage.setItem(showAtStorageKey, Date.now().toString());
      } catch (err) {}
    }
  };

  const canShow = () => {
    if (isPreview) {
      return true
    }
    let lastShow;
    try {
      lastShow = +(localStorage.getItem(showAtStorageKey) || "0");
    } catch (err) {}
    return !lastShow || lastShow < Date.now() - (showAgainFrom || 1) * 1000;
  };

  const onScroll = () => {
    const scrollTop =
      document.documentElement.scrollTop || document.body.scrollTop;
    const scrollHeight =
      document.documentElement.scrollHeight || document.body.scrollHeight;
    if (
      canShow() &&
      scrollTop / (scrollHeight - document.documentElement.clientHeight) >=
        +scrollPercent / 100
    ) {
      show();
      window.removeEventListener("scroll", onScroll);
    }
  };

  const onMouseOut = (event: MouseEvent) => {
    if (!event.relatedTarget && !(event as any).toElement) {
      if (canShow()) {
        show();
        document.removeEventListener("mouseout", onMouseOut);
      }
    }
  };

  useEffect(() => {
    if (!isPreview && formView === "popup") {
      switch (condition) {
        case "enter":
          setTimeout(() => {
            if (canShow()) {
              show();
            }
          }, (delay || 1) * 1000);
          break;
        case "scroll":
          window.addEventListener("scroll", onScroll);
          break;
        case "cursorout":
          document.addEventListener("mouseout", onMouseOut);
          break;
      }
    }
  }, []);

  return {
    state,
    setState,
    show,
    hide: () => setState(false),
    canHide: !isPreview || condition === "button" || formView === "widget",
  };
};

export default useFormShow;
