import { Fragment, h } from "preact";

const Paragraph = ({ text }: { text: string }) => {
    const lines = text.split('\n')
    return lines.length > 1 ? (
        <Fragment>
            {text.split('\n').map(line => (
                <Fragment>
                    {line}<br />
                </Fragment>
            ))}
        </Fragment>
    ) : (
        <Fragment>{text}</Fragment>
    )
}

export default Paragraph