
const monthLength = (year: number, month: number) => new Date(year || 2000, month, 0).getDate()

const datePattern = /\d{1,2}\.\d{1,2}((.\d{4})?)/;

const MONTH_NAMES = ["января", "февраля", "марта", "апреля", "мая", "июня",
    "июля", "августа", "сентября", "октября", "ноября", "декабря"];

const parseRaw = (str: string) => {
    var month, spl;
    if (datePattern.test(str)) {
        spl = str.split(".");
        month = +spl[1];
    } else {
        spl = str.toLowerCase().split(" ");
        month = MONTH_NAMES.indexOf((spl[1] || '').toLowerCase()) + 1;
    }
    return [+spl[0], month, +spl[2]];
}

export const parse = (str: string) => {
    const [day, month, year] = parseRaw(str);
    return new SimpleDate(day, month, year);
}

export const isValid = (str: string) => {
    if (!str) {
        return false;
    }
    var raw = parseRaw(str);
    if (!raw[0] || !raw[1] || raw[1] < 1 || raw[1] > 12) {
        return false;
    }
    if (!raw[0] || raw[0] > monthLength(raw[2], raw[1])) {
        return false;
    }
    if (raw[2] != null && (raw[2] < 1 || raw[2] > 3000)) {
        return false;
    }
    return true;
}

export default class SimpleDate {
    day: number
    month: number
    year: number
    constructor(day: number, month: number, year: number) {
        this.day = day;
        this.month = month;
        this.year = year;
    }
    format() {
        var result = [this.day, MONTH_NAMES[this.month - 1]];
        if (this.year) {
            result.push(this.year);
        }
        return result.join(' ');
    }

}