import { Base64 } from "js-base64";
import { v4 as uuid } from "uuid";
import { h, RefCallback } from "preact";
import { useEffect, useMemo, useState } from "preact/hooks";
import useFormShow from "../hooks/form-show";
import useJsonp from "../hooks/jsonp";
import useValidate from "../hooks/validate";
import CloseIcon from "../icons/close";
import { FormDto } from "../types/Form";
import { processScripts } from "../utils/dom";
import { collectSubscriberFields, trackView } from "../utils/track";
import { collectUtm } from "../utils/utm";
import Cover from "./Cover";
import Field from "./Field";
import NetworkSelect from "./NetworkSelect";
import OptIn from "./OptIn";
import Paragraph from "./Paragraph";
import PolicyField from "./PolicyField";
import SubmitButton from "./SubmitButton";

export default ({
  form: { form, coversUrl, submitUrls = {} },
  params: { mode, hideBranding = false }
}: {
  form: FormDto,
  params: { mode: 'web' | 'preview' | 'page', hideBranding?: boolean }
}) => {

  if (mode === 'page') {
    form.formView = 'form'
  }

  const jsonp = useJsonp()
  const [stage, setStage] = useState<'initial' | 'opt-in'>('initial')
  const [submitting, setSubmitting] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [submitUrl, setSubmitUrl] = useState('')
  const [sessionId, setSessionId] = useState('')
  const [fill, setFill] = useState(({
    ...collectSubscriberFields(form.id),
    ...collectUtm()
  }))
  const [policyCheck, setPolicyCheck] = useState(true)
  const [network, setNetwork] = useState(
    form.networks.find(network => network === 'VK')
    || form.networks.find(network => network !== 'EM')
    || 'EM'
  )
  const { state: isShown, show, hide, canHide } = useFormShow(form.id, mode, form.formView, form.formViewOptions || {})
  const externalEmailForm = useMemo(() => form.platformSettings['EM']?.externalForm, [form])
  const visibleFields = useMemo(
    () => form.fields
      .filter(({ hiddenForNetworks = [] }) => !hiddenForNetworks.includes(network)
      ),
    [form, network, externalEmailForm]
  )
  const shouldShowExternalEmailForm = useMemo(() => (externalEmailForm || externalEmailForm === '') && network === 'EM', [network, externalEmailForm])
  const { validate, invalidFieldNames } = useValidate(visibleFields)

  const setExternalFormContainerRef: RefCallback<HTMLDivElement> = (container: HTMLDivElement | null) => {
    if (container && !container.innerHTML) {
      container.innerHTML = externalEmailForm || ''
      processScripts(container)
    }
  }


  const setField = (name: string, value: any) => setFill({
    ...fill,
    [name]: value
  })

  const handleWrapClick = (event: Event) => {
    if (form.formView === 'popup' && event.target === event.currentTarget) {
      hide()
    }
  }

  useEffect(() => {
    if (mode !== 'preview') {
      trackView(form.subscriptionId, form.id)
    }
  }, [])

  useEffect(() => {
    setSubmitted(false)
  }, [network])

  useEffect(() => {
    if (mode === 'preview') return
    if (submitted) {
      validate(network, fill)
    }
    if (network === 'EM') {
      setSubmitUrl('')
    }
    if (network === 'VK') {
      setSubmitUrl(validate(network, fill, true) && policyCheck ? (
        `${submitUrls[network]}?f&formId=${form.id}&formFields=${encodeURIComponent(Base64.encode(JSON.stringify(fill)))}`
      ) : '')
    }
    if (network === 'TG') {
      const newSessionId = uuid()
      setSessionId(newSessionId)
      setSubmitUrl(validate(network, fill, true) && policyCheck ? (
        `${submitUrls[network]}?start=${newSessionId}`
      ) : '')
    }
  }, [network, fill, policyCheck])

  const onSubmit = async () => {
    if (mode === 'preview') {
      return
    }
    setSubmitted(true)
    if (!validate(network, fill)) {
      return
    }
    setSubmitting(true)
    try {
      if (network === 'EM') {
        const { id } = await jsonp.createSession(form.id, `EM_${fill['email']}`, fill)
        setSessionId(id)
        setStage('opt-in')
      }
      if (network === 'TG') {
        await jsonp.updateSession(sessionId, form.id, fill)
      }
    } finally {
      setSubmitting(false)
    }
  }

  const wrapClassName = `ss-form-wrap--${form.formView || 'form'}`

  return (
    <div class={[
      'ss-form-wrap',
      `ss-form-wrap--${network.toLowerCase()}`,
      `ss-form-wrap--${mode}`,
      wrapClassName,
      `${wrapClassName}-${form.formViewOptions?.position || 'right'}`,
      `${wrapClassName}-${form.formViewOptions?.condition || 'enter'}`,
      isShown ? 'ss-show' : ''
    ].join(' ')}>
      <button class="ss-form-toggle" onClick={show}>
        {form.formViewOptions?.buttonCaption || ''}
      </button>
      <div class="ss-form-container" onClick={handleWrapClick}>
        {stage === 'initial' ? (
          <div class="ss-form">
            {(form.formView === 'widget' || form.formView === 'popup') && canHide && (
              <button class="ss-form-close" onClick={hide}>
                <CloseIcon />
              </button>
            )}
            {form.cover && <Cover baseUrl={coversUrl} cover={form.cover} />}
            <div class="ss-form-texts">
              {form.title && (
                <div class="ss-form-title">
                  <Paragraph text={form.title} />
                </div>
              )}
              {form.subtitle && (
                <div class="ss-form-subtitle">
                  <Paragraph text={form.subtitle} />
                </div>
              )}
              {form.description && (
                <div class="ss-form-description">
                  <Paragraph text={form.description} />
                </div>
              )}
            </div>
            {form.networks?.length > 1 && <NetworkSelect networks={form.networks} title={form.networkSelectTitle} value={network} onChange={setNetwork} />}
            <div class={`ss-external-form ${shouldShowExternalEmailForm ? 'ss-show' : 'ss-hide'}`} ref={setExternalFormContainerRef}>
              {mode === 'preview' ? '*После вставки на сайт здесь будет отображаться email-форма' : ''}
            </div>
            <div class={`ss-form-content${shouldShowExternalEmailForm ? ' ss-hide' : ''}`}>
              {visibleFields.map(field => (
                <Field
                  field={field}
                  value={fill[field.name]}
                  onChange={(val: any) => setField(field.name, val)}
                  invalid={submitted && invalidFieldNames.includes(field.name)} />
              ))}
              <div class="ss-submit-wrap">
                <SubmitButton caption={form.submitButton.caption} network={network} onClick={onSubmit} url={submitUrl} disabled={submitting} />
              </div>
              {form.bottomNotice && (
                <div class="ss-bottom-notice">
                  <Paragraph text={form.bottomNotice} />
                </div>
              )}
              {form.securityPolicyUrl && (
                <PolicyField text={form.securityPolicyText} url={form.securityPolicyUrl} value={policyCheck} onChange={setPolicyCheck} />
              )}
              {!hideBranding && (
                <div class="ss-branding">
                  Работает на <a href="https://socialsend.ru" target="_blank">
                    SocialSend.ru
                  </a>
                </div>
              )}
            </div>
          </div>
        ) : stage === 'opt-in' ? (
          <OptIn text={form.platformSettings['EM']?.optInText || ''} />
        ) : null}
      </div>
    </div>
  )
}