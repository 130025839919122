import { h } from "preact";

export default ({
  name,
  value,
  options,
  onChange,
}: {
  name: string;
  value: any;
  options: string[];
  onChange: (value: string) => any;
}) => {
  return (
    <div class="ss-radio-wrap">
      {options?.map((label) => (
        <label
          class={`ss-radio${
            value && value.indexOf(label) !== -1 ? " ss-selected" : ""
          }`}
        >
          <span class="ss-radio-ctrl"></span>
          <input
            name={name}
            type="radio"
            onChange={({ target }) =>
              onChange((target as HTMLInputElement)!.value)
            }
            value={label}
          />
          {label}
        </label>
      ))}
    </div>
  );
};
