import { h } from "preact";
import { Form } from "../types/Form";
import { parseYoutubeVideoCode } from "../utils/strings";

export default ({ baseUrl, cover: { type, src, youtubeUrl, youtubeAutoplay, youtubeMute } }: { baseUrl: string, cover: Form.Cover }) => {
  if (type === 'image' && src) {
    return (
      <div class="ss-cover">
        <img src={`${baseUrl}${src}`} alt="cover" />
      </div>
    )
  } else if (type === 'youtube' && youtubeUrl) {
    const code = `${parseYoutubeVideoCode(youtubeUrl)}`
    return (
      <div class="ss-cover">
        <iframe id={code} width="560" height="315" src={`https://www.youtube.com/embed/${code}?autoplay=${+youtubeAutoplay}&mute=${+youtubeMute}&rel=0&controls=0&showinfo=0&enablejsapi=1`} frameBorder="0" allowFullScreen />
      </div>
    )
  } else {
    return null
  }
}